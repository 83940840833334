const API_URL = process.env.REACT_APP_API_URL;

export type PreEncodedTag = {
  preEncodedNumber: string,
  tagId: string,
};

const getTag = (tagId:string, useDevDB?: boolean) => new Promise((resolve, reject) => {
  const location = useDevDB ? '?type=dev' : '';
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags/${tagId}${location}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getTagIdByPreEncode = (encodedId: string): Promise<PreEncodedTag> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags/encodedId/${encodedId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateTag = (
  tagId:string,
  schemeId: string,
  locationId: string,
  tagType: string,
  useDevDB?: boolean,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  const location = useDevDB ? '?type=dev' : '';
  fetch(`${API_URL}/tags/${tagId}${location}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Scheme: schemeId, Location: locationId, tagType }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createTag = (
  tagId:string,
  schemeId: string,
  locationId: string,
  tagType: string,
  needsCode?: boolean,
  letter?: string,
) => new Promise((resolve, reject) => {
  const code = needsCode ? '?needsCode=true' : '?needsCode=false';
  const tagLetter = letter ? `&letter=${letter}` : '&letter=A';
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags${code}${tagLetter}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tagId, Scheme: schemeId, Location: locationId, tagType,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteTag = (tagId:string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags/${tagId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const activateTag = (tagId: string) => new Promise<void>((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags/activate/${tagId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return resolve();
    })
    .catch((error) => {
      reject(error);
    });
});

const deactivateTag = (tagId: string) => new Promise<void>((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/tags/deactivate/${tagId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return resolve();
    })
    .catch((error) => {
      reject(error);
    });
});

const TagService = {
  createTag,
  deleteTag,
  getTag,
  getTagIdByPreEncode,
  updateTag,
  activateTag,
  deactivateTag,
};

export default TagService;
