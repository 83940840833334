import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { AiFillDiff, AiFillFlag, AiFillStop } from 'react-icons/ai';

import {
  FaStore, FaStamp, FaQuestion, FaStopwatch,
} from 'react-icons/fa';
import { GiPresent, GiBackwardTime } from 'react-icons/gi';

import TagService from '../../../Services/TagService';
import TagUpdate from './TagUpdate';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import IconWithText from '../../../Components/BasicComponents/IconWithText';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import LiveToggleButton from '../../../Components/BasicComponents/LiveToggleButton';
import EditButton from '../../../Components/BasicComponents/EditButton';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import Toggle from '../../../Components/BasicComponents/Toggle';
import SchemeService from '../../../Services/SchemeService';

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0px',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  alignSelf: 'center',
  justifySelf: 'center',
  height: '250px',
  borderRadius: '10px',
  padding: '5px',
  borderColor: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
}));

type Props = {
  goBack: () => void,
  loadTagDetails: (tagId: string) => void,
  isTagLive: boolean,
  schemeData: any,
  tagData: any,
  activationCode?: string,
  partnerId: string,
};

function TagDetails(props: Props) {
  const {
    goBack,
    loadTagDetails,
    isTagLive,
    schemeData,
    tagData,
    activationCode,
    partnerId,
  } = props;

  const [openDeactivateDialog, setOpenDeactivateDialog] = useState<boolean>(false);
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false);
  const [openEditFlow, setOpenEditFlow] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [isExcluded, setIsExcluded] = useState<boolean>(false);

  const permissions = ['loyalty:tags:all', 'loyalty:tags:update'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const activateTagButton = () => {
    setOpenActivateDialog(true);
  };

  const deactivateTagButton = () => {
    setOpenDeactivateDialog(true);
  };

  const handleActivateTag = async () => {
    try {
      await TagService.activateTag(tagData.tagId)
        .then(() => {
          loadTagDetails(tagData.tagId);
        });
    } catch (e:any) {
      setErrorText(e.message);
      setOpenErrorDialog(true);
    }
    setOpenActivateDialog(false);
  };

  const handleDeactivateTag = async () => {
    try {
      await TagService.deactivateTag(tagData.tagId)
        .then(() => {
          loadTagDetails(tagData.tagId);
        });
    } catch (e:any) {
      setErrorText(e.message);
      setOpenErrorDialog(true);
    }
    setOpenDeactivateDialog(false);
  };

  const excludeTag = async () => {
    try {
      await SchemeService.excludeTag(partnerId, tagData.schemeId, tagData.tagId)
        .then(() => {
          loadTagDetails(tagData.tagId);
        });
    } catch (e:any) {
      setErrorText(e.message);
      setOpenErrorDialog(true);
    }
    setOpenDeactivateDialog(false);
  };

  const removeExclusion = async () => {
    try {
      await SchemeService.removeExclusion(partnerId, tagData.schemeId, tagData.tagId)
        .then(() => {
          loadTagDetails(tagData.tagId);
        });
    } catch (e:any) {
      setErrorText(e.message);
      setOpenErrorDialog(true);
    }
    setOpenDeactivateDialog(false);
  };

  useEffect(() => {
    if (schemeData?.restrictions?.exclusionTags?.[tagData.tagId]) {
      setIsExcluded(true);
    } else {
      setIsExcluded(false);
    }
  }, [schemeData]);

  return (
    <Content>

      <ButtonRow>
        <BackButton onClick={goBack} />
        <EditButton disabled={!canEdit} onClick={() => setOpenEditFlow(true)} />
      </ButtonRow>
      <LiveToggleButton
        isLive={isTagLive}
        onActivate={activateTagButton}
        onDeactivate={deactivateTagButton}
        disabled={!canEdit}
      />

      {activationCode ? (
        <>
          <TitleComponent text={`Activation Code : ${activationCode}`} />
          <StyledGrid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Please take note of the activation code before leaving this page.
                It can only be viewed directly after creating this tag.
                If you wish to find this code again it can be found on the business section of the portal
              </Typography>
            </Grid>
          </StyledGrid>
        </>
      ) : null}

      {/* Tag information section */}
      {tagData.tagType && <TitleComponent text={`Tag Type : ${tagData.tagType}`} /> }
      <TitleComponent text={`Tag ID : ${tagData.tagId}`} />
      <StyledGrid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Scheme ID" icon={<AiFillDiff />} text={tagData.schemeId} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Location ID" icon={<AiFillFlag />} text={tagData.locationId} />
        </Grid>
      </StyledGrid>

      {/* Scheme information section */}
      {schemeData ? (
        <>
          <TitleComponent text="Linked Scheme Information" />
          <StyledGrid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <IconWithText description="Store Name" icon={<FaStore />} text={schemeData.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconWithText description="Reward" icon={<GiPresent />} text={schemeData.reward} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconWithText description="Reward Plural" icon={<GiPresent />} text={schemeData.rewardPlural} />
            </Grid>
            {schemeData.rewardTerm ? (
              <Grid item xs={12} sm={6}>
                <IconWithText description="Reward Term" icon={<GiPresent />} text={schemeData.rewardTerm} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <IconWithText
                description="Add Stamps"
                icon={<FaQuestion />}
                text={schemeData.addStamp === true ? 'yes' : 'no'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconWithText description="Max Stamps" icon={<FaStamp />} text={schemeData.max} />
            </Grid>
          </StyledGrid>

          {schemeData?.restrictions ? (
            <>
              <TitleComponent text="Scheme Restrictions" />
              <StyledGrid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <IconWithText
                    description="Stamps before Restriction"
                    icon={<AiFillStop />}
                    text={schemeData.restrictions.Amount}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <IconWithText
                    description="Restriction Period"
                    icon={<GiBackwardTime />}
                    text={schemeData.restrictions.Period}
                  />
                </Grid>
                <Grid item container xs={12} sm={6} alignContent="center">
                  <IconWithText
                    description="Tag is effected by Restriction"
                    icon={<FaStopwatch />}
                    text={' '}
                  />
                  <Toggle
                    activeText="Excluded"
                    disabledText="Restricted"
                    isLive={isExcluded}
                    disabled={!canEdit}
                    activateAction={excludeTag}
                    deactivateAction={removeExclusion}
                  />
                </Grid>

              </StyledGrid>
            </>
          ) : null}

          <TitleComponent text="Logo and Loyalty Card" />
          <StyledGrid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledImage src={tagData.pocket.logoImage} alt="logo" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledImage src={schemeData.schemeImages[0]} alt="loyalty" />
            </Grid>
          </StyledGrid>
        </>

      ) : (<TitleComponent text="No Scheme linked to this tag" />)}

      <ConfirmDialog
        dialogTitle="DEACTIVATE TAG"
        dialogText="Are you sure you want to Deactivate this tag?
        All information will be moved to the developer database
        but this tag will no longer be 'Active'"
        open={openDeactivateDialog}
        handleCancel={() => setOpenDeactivateDialog(false)}
        handleConfirm={handleDeactivateTag}
        isDelete
      />

      <ConfirmDialog
        dialogTitle="ACTIVATE TAG"
        dialogText="Are you sure you want to Activate this tag?
        All information here will be copied to the production database
        and this tag will then be 'Active'"
        open={openActivateDialog}
        handleCancel={() => setOpenActivateDialog(false)}
        handleConfirm={handleActivateTag}
        isDelete
      />

      {openEditFlow
        ? (
          <TagUpdate
            isCreateTag={false}
            useEncode={false}
            loadTagDetails={loadTagDetails}
            isLive={isTagLive}
            tagId={tagData.tagId}
            setOpenFlow={setOpenEditFlow}
          />
        ) : null}

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle=""
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

    </Content>
  );
}

export default TagDetails;
